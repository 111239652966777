/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.6.0/dist/jquery.min.js
 * - /npm/bootstrap@4.3.1/dist/js/bootstrap.bundle.min.js
 * - /npm/moment@2.29.1/min/moment.min.js
 * - /npm/moment@2.29.1/locale/da.min.js
 * - /npm/flatpickr@4.6.9/dist/flatpickr.min.js
 * - /npm/flatpickr@4.6.9/dist/l10n/da.min.js
 * - /npm/focus-visible@5.2.0/dist/focus-visible.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
